@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Poppins', monospace;
	transition: all .3s ease;
}

.flex {
	display:flex;
}

.row {
	display:flex;
	flex-direction:row;
}

.column {
	display:flex;
	flex-direction:column;
}

.flexFull {
	flex:1;
}

.aCenter {
	align-items: center;
}

.jCenter {
	justify-content: center;
}

.jSpaceBetween {
	justify-content: space-between;
}

.cDark {
	color:#111111;
}

.cGray {
	color:#717171;
}

.cMain {
	color:#FF385C;
}

.displayNone {
	display:none !important;
}

.container {
	margin:auto;
	width:1400px;
	max-width:100%;
}
.containerMin {
	margin:auto;
	width:1000px;
	max-width:100%;
}
.twenty form {
	max-width:100vw;
	overflow-x:auto;
}

@media (max-width:1420px) {
	.container {width:1000px;}
} 
@media (max-width:1000px) {
	.container , .containerMin {width:768px;}
	#root section section.grid-styling {
		grid-template-columns: repeat(2, 1fr);
	}
	.menu a span {
		display:none;
	}
}

@media (min-width:768px) and (max-width:1000px) {
	section .mainContainer .mainBody {
		height:calc(100vh - 216px)
	}
}

@media (max-width:768px) {
	.container {
		padding:0px 30px;
		flex-direction:column !important;
		.container {
			padding:0px !important;
		}
	}
	#root section section.grid-styling {
		grid-template-columns: repeat(1, 1fr);
	}
}